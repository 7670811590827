.header .navbar-item {
  @apply w-[85px] cursor-pointer text-center text-white;
}
.header {
  z-index: 10;
  overflow: hidden;
}
.nav-menu-vertical {
  width: 100%;
  height: 100% !important;
  z-index: 9;
  background-image: url("../../assets/main_banner_bg.svg") !important;
}

.nav-menu {
  background: white;
  transition: 0.5s;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
  overflow: auto;
  position: fixed;
  left: -100%;
}
.nav-menu-open {
  left: 0px !important;
  transition: 0.5s;
}
