/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Anek Gurmukhi", sans-serif;
}
.content-wrapper {
  @apply mx-auto max-w-[1030px];
}
.btn {
  line-height: normal;
  @apply cursor-pointer rounded px-4 py-3 text-center text-base font-bold;
}
.btn-primary {
  @apply bg-primary text-white;
}
.btn-primary.disable{
  @apply pointer-events-none opacity-30;
}
.font-inria {
  font-family: "Inria Sans", sans-serif;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    /* Firefox */
    -moz-appearance: textfield;
}