.header-bg {
  background-image: url("../../assets/main_banner_bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: bottom;
  @apply relative top-0 h-[771px] w-full rounded-bl-xl;
}
.honeycomb {
  background-image: url("../../assets/honeycomb.png");
  background-size: cover;
}
.ideas {
  background: linear-gradient(90deg, #c08247 46.28%, #c0af59 66.61%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.software {
  background: linear-gradient(90deg, #4478e8 0%, #87bec1 36.2%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.card {
  border: 1px solid #d9d9d9;
  padding: 60px 32px 32px 32px;
  @apply rounded;
}
.client-wrapper {
  border: 1px solid #d9d9d9;
  @apply rounded px-8 py-4;
}
.qoute {
  @apply absolute left-8 top-[-28px];
}
.contactbox {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0px 46px 0px rgba(148, 148, 148, 0.18);
}

.conatct-label-wrapper {
  @apply h-[22px] w-[230px];
}

.conatct-label {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.contact-details {
  color: #bdb6bf;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.form-label {
  color: #151515;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 12px;
}

.form-field {
  display: flex;
  @apply w-full md:w-[271px];
  padding: 12px;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  background: #f2f2f4;
}

.contact-form-bg {
  background-image: url("../../assets/form_bg_img.png");
  background-size: cover;
}

.contact-form-bg-cover {
  background: url("../../assets/scalix_contact_background.png") lightgray 50% /
    cover no-repeat;
}
